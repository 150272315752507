<template>
  <NuxtLink
    :to="props.link.href ? props.link.href : localePath(props.link.route)"
    :external="props.link.href ? true : false"
    :target="props.link.target"
    class="link-main-underline text-l"
    :class="{
      '--light': props.light,
      '--diagonal': props.diagonal,
    }"
  >
    <span>{{ props.link.title || $t(`navigation.${props.link.key}`) }}</span>
    <img
      :src="props.light ? arrowLight : arrowDark"
      :alt="props.light ? 'Ico arrow forward light' : 'Ico arrow forward dark'"
      width="16"
      height="14"
    />
  </NuxtLink>
</template>

<script>
export default {
  name: 'WidgetLinkMainUnderline',
}
</script>

<script setup>
import { NuxtLink } from '#components'

import arrowLight from '~/assets/img/ico/ico-arrow-forward-light.svg'
import arrowDark from '~/assets/img/ico/ico-arrow-forward-dark.svg'

const props = defineProps({
  light: Boolean,
  diagonal: Boolean,
  link: Object,
})
</script>

<style lang="scss">
@import './style.scss';
</style>
